$(document).ready(function() {
  if (!localStorage.bannerClosed) {
    $('.cookieinfo-banner').css('display', 'inherit');
  } else {
    $('.cookieinfo-banner').css('display', 'none');
  }
  $('.cookieinfo-accept').on('click', function() {
    $('.cookieinfo-banner').css('display', 'none');
    $('#cookie_policy_accepted').val(1);
    localStorage.bannerClosed = 'true'
  });
});